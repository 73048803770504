<template>
  <div class="reserve-board-wrap">
    <strong class="reserve-board-wrap--title">상담현황</strong>
    <ul class="reserve-board-list">
      <li v-for="cs in CS_LIST" :key="cs.CUST_CNSL_ID" :class="{active : cs.active}">
        <span class="reserve-board-list--index">{{ cs.SEAT_NO }}</span>
        <span class="reserve-board-list--num">{{ cs.WAIT_NUM }}</span>
      </li>
    </ul>
  
  </div>
</template>

<script>
  import { stompUtil } from "@/store/stomp-util.js";

  export default {
    name: 'MENU_CAP0520',
    components: {},
    
    metaInfo() {
		  return {
        title: "상담현황",
      }
    },

    props: {
    },

    data() {
      return {
        //상담리스트
        CS_LIST: [
          // { SEAT_NO: '1', WAIT_NO: '', active: false },
          // { SEAT_NO: '2', WAIT_NO: '', active: false },
          // { SEAT_NO: '3', WAIT_NO: '', active: false },
          // { SEAT_NO: '4', WAIT_NO: '', active: false },
          // { SEAT_NO: '5', WAIT_NO: '', active: false },
        ],
        CS_LNG:0,
        BUSS_CODE:this.$route.query.buss_code.replace(/[\"\']/gi,''),
        CMP_ID:this.$route.query.cmp_id.replace(/[\"\']/gi,''),
        CMP_NAME:this.$route.query.cmp_name.replace(/[\"\']/gi,''),
      }
    },

    mounted() {
      this.init();
    },

    computed: {
      initHeaders(){
        return {
        };
      },
      paramData(){
        return {
        };
      },
    },

    methods: {
      init(){
        this.COUNSELING_COUNT = this.startInterval(this.counselingCount, 3);
      },

      startInterval(callback, second) {
        clearInterval(this.COUNSELING_COUNT);
        callback();
        return setInterval(callback, second * 1000);
      },

      // DB에서 데이터 가져오기
      async counselingCount() {
        let requestData = {
          headers: {},
          sendData: {},
        };
        //header 세팅
        requestData.headers["URL"] = "/api/hlw/campaign/work/counseling/select";

        //sendData 초기화
        requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;              //사업지 아이디
        requestData.sendData["CMP_ID"] = this.CMP_ID;                   //캠페인 아이디

        let response = await this.common_postCall(requestData);

        if (response.HEADER.ERROR_FLAG) {
        
          let msg = '관리자에게 문의해주세요.';
          if (!this.mixin_isEmpty(response.HEADER.MSG)) {
            msg = response.HEADER.MSG;
          }
          this.common_alert(msg, "error");
          return;
        }

        this.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: false
        });

        this.CS_LIST=response.DATA;

        for(var index=0; index < response.DATA.length; index++){
          this.CS_LIST[index].WAIT_NUM = response.DATA[index].CNSL_WAIT_NUM;
          if(response.DATA[index].CNSL_WAIT_NUM==0){
            this.CS_LIST[index].active = false;
          }else{
            this.CS_LIST[index].active = true;
          }
        }
      },
    },
  }
</script>

<style>
</style>